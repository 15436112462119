@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,600,700);
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#login-grid {
  display: grid;
  grid-template-areas: "main";
  grid-template-columns: 400px;
  justify-content: center;
}

#login-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6em;
  padding: 4em;
}

a {
  text-decoration: none;
}

.device_spinner {
  position: absolute;
  z-index: 999;
  left: 48%;
  top: 50%;
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgb(227, 83, 31, 0.35);
  border-right: 6px solid rgb(227, 83, 31, 0.35);
  border-bottom: 6px solid rgb(227, 83, 31, 0.35);
  border-top: 6px solid rgb(227, 83, 31, 0.18);
  border-radius: 100%;
}

.login_spinner {
  position: absolute;
  z-index: 999;
  left: 47.6%;
  top: 30%;
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgb(227, 83, 31, 0.35);
  border-right: 6px solid rgb(227, 83, 31, 0.35);
  border-bottom: 6px solid rgb(227, 83, 31, 0.35);
  border-top: 6px solid rgb(227, 83, 31, 0.18);
  border-radius: 100%;
}

.total_device_spinner {
  position: absolute;
  z-index: 999;
  top: 50%;
  right: 40%;
  height: 30px;
  width: 30px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgb(227, 83, 31, 0.35);
  border-right: 6px solid rgb(227, 83, 31, 0.35);
  border-bottom: 6px solid rgb(227, 83, 31, 0.35);
  border-top: 6px solid rgb(227, 83, 31, 0.18);
  border-radius: 100%;
}

.status-spinner {
  position: absolute;
  z-index: 999;
  top: 18%;
  right: 32%;
  height: 30px;
  width: 30px;
  margin: 0px auto;

  -webkit-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgb(227, 83, 31, 0.35);
  border-right: 6px solid rgb(227, 83, 31, 0.35);
  border-bottom: 6px solid rgb(227, 83, 31, 0.35);
  border-top: 6px solid rgb(227, 83, 31, 0.18);
  border-radius: 100%;
}

.fade-appear {
  opacity: 0.01;
  transform: translate(0, -10px);
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 300ms ease-in;
}
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: all 600ms ease-in;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms linear;
}
.fade-exit-done {
  opacity: 0;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

th {
  font-weight: 500 !important;
  text-align: center !important;
}
td {
  text-align: center !important;
}

.MuiSwitch-track {
  background-color: rgb(197, 196, 196) !important;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

/*------------------------------------------
  Foundation Media Queries 
   http://foundation.zurb.com/docs/media-queries.html
--------------------------------------------*/

/* Small screens - MOBILE */
@media only screen {
  td {
    font-size: 0.88em !important;
    padding: 0.2em 4em 0.2em 0.2em !important;
  }
} /* Define mobile styles - Mobile First */

@media only screen and (max-width: 40em) {
  td {
    font-size: 0.88em !important;
    padding: 0.2em 4em 0.2em 0.2em !important;
  }
} /* max-width 640px, mobile-only styles, use when QAing mobile issues */

/* Medium screens - TABLET */
@media only screen and (min-width: 40.063em) {
  td {
    font-size: 0.88em !important;
    padding: 0.2em 4em 0.2em 0.2em !important;
  }
} /* min-width 641px, medium screens */

@media only screen and (min-width: 40.063em) and (max-width: 64em) {
  td {
    font-size: 0.88em !important;
    padding: 0.2em !important;
  }
} /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

/* Large screens - DESKTOP */
@media only screen and (min-width: 64.063em) {
  td {
    font-size: 0.88em !important;
    padding: 0.2em !important;
  }
} /* min-width 1025px, large screens */

@media only screen and (min-width: 64.063em) and (max-width: 90em) {
  td {
    font-size: 0.88em !important;
    padding: 0.2em !important;
  }
} /* min-width 1024px and max-width 1440px, use when QAing large screen-only issues */

/* XLarge screens */
@media only screen and (min-width: 90.063em) {
  td {
    font-size: 0.88em !important;
    padding: 0.2em !important;
  }
} /* min-width 1441px, xlarge screens */

@media only screen and (min-width: 90.063em) and (max-width: 120em) {
  td {
    font-size: 0.88em !important;
    padding: 0.2em !important;
  }
} /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */

/* XXLarge screens */
@media only screen and (min-width: 120.063em) {
} /* min-width 1921px, xlarge screens */

/*------------------------------------------*/

/* Portrait */
@media screen and (orientation: portrait) {
  /* Portrait styles here */
}
/* Landscape */
@media screen and (orientation: landscape) {
  /* Landscape styles here */
}

/* CSS for iPhone, iPad, and Retina Displays */

/* Non-Retina */
@media screen and (-webkit-max-device-pixel-ratio: 1) {
}

/* Retina */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (-o-min-device-pixel-ratio: 3/2),
  only screen and (min--moz-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
}

/* iPhone Portrait */
@media screen and (max-device-width: 480px) and (orientation: portrait) {
}

/* iPhone Landscape */
@media screen and (max-device-width: 480px) and (orientation: landscape) {
}

/* iPad Portrait */
@media screen and (min-device-width: 481px) and (orientation: portrait) {
}

/* iPad Landscape */
@media screen and (min-device-width: 481px) and (orientation: landscape) {
}

